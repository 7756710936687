import './Notifications.css';
import { useEffect, useState } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { put } from '../../api/apiCalls';

function Notifications({ messages, onClearNotifications }) {
    const [isOpen, setIsOpen] = useState(false);
    const [unreadNotifications, setUnreadNotifications] = useState(0)

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const isDisabled = messages.length <= 0;

    async function handleNotificationClick(id, path){
        await put(`Notification?id=${id}`)
        window.location.href = path
    }

    useEffect(() => {
        let count = 0
        sortNotifications(messages)
        if(messages.length <= 0) setUnreadNotifications(0)
        messages.forEach(element => {
            if(element.status == 'unread') count += 1

            setUnreadNotifications(count)
        });
    }, [messages])

    function sortNotifications(messages){
        messages.sort((a, b) => {
            if (a.status === 'unread' && b.status !== 'unread') return -1;
            if (a.status !== 'unread' && b.status === 'unread') return 1;
            
            return b.id - a.id;
          });
    }
    return (
        <div className={`notification-container ${isDisabled ? 'disabled' : ''}`}>
            <div className="notification-icon" onClick={toggleDropdown}>
                <NotificationsIcon fontSize='large' color='inherit'/>
                <span className="notification-count">{unreadNotifications}</span>
            </div>
            {isOpen && (
                <div className="notification-dropdown">
                    <ul className="notification-list">
                        {messages.map(message => (
                            <li key={message.id}>
                                <button 
                                    className={message.status === 'read' ? 'read' : ''}
                                    onClick={() => handleNotificationClick(message.id, message.path)}
                                >
                                    {message.content}
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div className='notifications-footer'>
                        <button 
                            type='button' 
                            className='notifications-clear-all-btn' 
                            onClick={onClearNotifications}>
                            Clear All
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Notifications;
