import WisdomLogo from "../../images/Wisdom logo transparent.png"
import "./SignedPages.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { loginCall, get } from "../api/apiCalls";
import { ReactSession } from 'react-client-session';
import { useLocation } from "react-router-dom";
import Keycloak from "keycloak-js";
import LoadingSpinner from "../elements/UiElements/LoadingSpinner";
import { Toast } from "primereact/toast";

function SignIn() {
  const toast = useRef(null)
  const [session,setSession] = React.useState();
  let [email,setEmail] = React.useState('');
  let [password,setPassword] = React.useState('');
  let [authenticated,setAuthenticated] = useState(false);
  let [loadSSO,setLoadSSO] = useState(null);
  let [enableSso,setEnableSso] = useState(false);
  const { hash } = useLocation();
  ReactSession.setStoreType("sessionStorage");

  const showError = (text) => {
    toast.current.show({severity:'error', summary: 'Error', detail:text, life: 3000});
  }

  const login = async() =>   {
    let keys = { "email":email , "password": password, "sso" : authenticated };
    try {
      ReactSession.remove("User");
      await loginCall("Users/Login",keys,true);
      ReactSession.set("TrackerStatus", false)
      ReactSession.set("timeSpentInSeconds", 0)
      localStorage.setItem("LastUpdate", '')
      let user = ReactSession.get("User");
      if(typeof user == 'undefined') {
        showError('Wrong username or password');
      } else {
        if(user.project!=null){
          window.location.href = "/team";
        }
        else {
          window.location.href = "/summary";
        }
      }
      
    } catch(error) {
      setSession("Wrong username or password");

      console.error(error);
    }
  }
  
  const sso = async () => {
    setEnableSso(true);
    enableSso = true;
    await initKeyCloak(); 
  }

  const initKeyCloak = async () => {
    let settings = JSON.parse((await get("Settings",{settingName : "sso"})).value);
    let kcURL = String(settings.keycloakURL);
    let kcrealm = String(settings.realm);
    let kcclientID = String(settings.clientID);
    let ssoRedirectUrl = String(settings.ssoRedirectUrl);
    const keycloak = new Keycloak({
       url: kcURL,
       realm: kcrealm,
       clientId: kcclientID,
     });
     await keycloak.init({
      onLoad: 'login-required',
      redirectUri: ssoRedirectUrl,
      enableLogging: true,
      useNonce: false
    }).then(async auth => {
      setAuthenticated(authenticated);
      email = keycloak.idTokenParsed.email;
      authenticated = auth;
      if(keycloak.idTokenParsed !==null ){
        await login();
      }
    });
  }

  let getSSOstatus = async() => {
    let settings = JSON.parse((await get("Settings",{settingName : "sso"})).value);
    setLoadSSO(settings.showSSO);
  }

  useMemo(async () => {
    
      if(hash!=""){
        initKeyCloak();
      }

      if(loadSSO == null) {
        getSSOstatus();
      }
  },[]);

  useEffect(() => {
    if(password !== '' || email !== ''){
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          login(); 
        }
      };
      window.addEventListener('keydown', handleKeyDown);
  
      return () => {
          window.removeEventListener('keydown', handleKeyDown);
      };

    }

  }, [login])

  return (
    <div>
      <Toast ref={toast} position="top-center"/>
    {
      loadSSO !==null &&
    
      <div className="sign-in-page">
        {hash &&
        <div className="ssoLoading">
        <LoadingSpinner />
        </div>
        }
        <img className="wisdom-logo-img" src={WisdomLogo} alt="Wisdom Logo" />
        <div className="signed-header">Sign In</div>
        <div className="signed-sub-header">
          Enter the credentials provided by your manager/ administrator
        </div>
        <div className="sign-in-page-section">
          <input 
            className="input-text"
            type="email" 
            required="required" 
            placeholder="Email"
            onChange ={(e)=>setEmail(e.target.value)}
          />
          <input
            className="input-text"
            type="password"
            required="required"
            placeholder="Password"
            onChange ={(e)=>setPassword(e.target.value)}
          />
          <div className="signIn-btns">
            <button className="signIn-login-btn" disabled={password === '' || email === '' } onClick={login}>Sign In</button>
            {
              loadSSO && (
                <button className="signIn-sso-btn" onClick={sso}>
                <span>Sign in with SSO</span>
                </button>
              )
            }
          </div>
          
        </div>
        <div className="signed-pages-links">
        </div>
      </div>
    }
    </div>
  );
}

export default SignIn;
