import "./Header.css";
import Logo from "./Logo";
import Profile from "./Profile";
import DownloadButton from "../../elements/DownloadButton";
import { ReactSession } from 'react-client-session';
import Tracker from "../../elements/tracker/Tracker";
import Notifications from "../../elements/UiElements/Notifications";
import { useEffect, useRef, useState } from "react";
import * as signalR from '@microsoft/signalr'
import { get, remove } from "../../api/apiCalls";
import { hubURL } from '../../api/env';
import sound from '../../../sounds/notifiation.mp3'
import { Toast } from "primereact/toast";

function Header({title}) {
  const toast = useRef(null)
  const [connection, setConnection] = useState(null);
  const [messages, setMessages] = useState([])
  let user = ReactSession.get("User");

  const showInfo = (text) => {
    toast.current.show({severity:'success', summary: 'Success', detail:text, life: 3000});
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let requests = await get(`Notification?username=${user.username}`);
        setMessages(requests)
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
  
    fetchData();
    const token = sessionStorage.getItem("token");
    const newConnection = new signalR.HubConnectionBuilder()
    .withUrl(`${hubURL}`, { accessTokenFactory: () => token } ,{
    })
    .withAutomaticReconnect()
    .build()

    setConnection(newConnection)
  }, [])

  const playNotificationSound = () => {
    const audio = new Audio(sound);
    audio.play();
  };

  useEffect(() => {
    if(connection){
      connection.start()
      .then(() => {
        connection.invoke("GetConnectionId").then(id => console.log('Connection ID: ', id));
        console.log("Connected")
        connection.on("ReceiveMessage", message => {
          message.path = `/manager/user-requests/${message.toUserID}`
          console.log("Received notification: ", message.content);
          playNotificationSound()
          setMessages(messages => [...messages, message])
        })
      })
      .catch(e => console.log('Connection failed: ', e));
    }
  }, [connection])
  

  async function handleClearNotifications(){
    let key = {
      username: user.username
    }
    // calling Delete notifications endpoint
    let response = await remove("Notification", key)
    showInfo(response)
    setMessages([])
  }

  return (
    <div className="header">
      <Toast ref={toast} position="top-center"/>
      <div className="align-left">
        <Logo />
        <div className="pageName">{title}</div>
      </div>
      <div className="align-right">
        {user.webtracker_enabled &&(
          <Tracker />
        )}
        <div className="download-button">
        {user.role !=4 && !user.disabled  && (
          <DownloadButton />
          )}
        </div>
          <Notifications messages={messages} onClearNotifications={handleClearNotifications}/>
          <Profile />
      </div>
    </div>
  );
}

export default Header;
