import './DashboardProjects.css';
import DataTableBase from '../../ui/DataTableBase.js';
import Backdrop from '../../ui/Backdrop.js';
import ProjectsModal from '../../elements/dashboardElements/ProjectsModal.js';
import AddProjectsModal from '../../elements/dashboardElements/AddProjectsModal.js';
import PayrollModal from '../../elements/dashboardElements/PayrollModal.js'
import {post,get} from "../../api/apiCalls";
import React, { useState,useMemo, useRef} from 'react';
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";
import Switch from "react-switch";
import { ProjectTeamModal } from '../../elements/dashboardElements/ProjectTeamModal.js';
import { Toast } from 'primereact/toast';

function DashboardManagerProjects(props) {
  const toast = useRef(null)
  const [openModal, setOpenModal] = useState(false);
  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [payrollModal,setPayrollModal] = useState(false);
  const [currentManager, setCurrentManager] = useState({})
  let [projects,setProjects] = useState([]);
  let [loading,setLoading] = useState(true);
  let [disabled,setDisabled] = useState(false); 
  
  const showWarning = (text) => {
    toast.current.show({severity:'warning', summary: 'Warning', detail:text, life: 3000});
  }

  const showSuccess = (text) => {
    toast.current.show({severity:'success', summary: 'Success', detail:text, life: 3000});
  }

  const toggleOnlyActive = () => {
      disabled = !disabled;
      setDisabled(disabled);
      getProjects();
  }

  const columns = [
    {
      id: 'project',
      name: 'Project',
      selector: (row) => row.project,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'contact',
      name: 'Contact Name',
      selector: (row) => row.contact,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'manager',
      name: 'Manager',
      selector: (row) => row.manager,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 'shared',
      name: 'Shared Services',
      selector: (row) => row.shared,
      sortable: true,
      reorder: true,
      wrap: true,
      cell: (row) => {
        return (
          <div className='project-services' id={row.id}>
            {row.shared ? 'Yes' : 'No'}
          </div>
        );
      },
    },
    {
      id: 'disabled',
      name: 'Disabled',
      selector: (row) => row.disabled,
      sortable: true,
      reorder: true,
      wrap: true,
      cell: (row) => {
        return (
          <div className='project-services' id={row.id}>
            {row.disabled ? 'Yes' : 'No'}
          </div>
        );
      },
    },
    {
      id: 'details',
      name: 'Configuration',
      selector: (row) => row.details,
      cell: (row) => {
        return (
          <button
            className='project-details-buttons'
            onClick={() => showDetails(row)}
          >
            Config
          </button>
        );
      },
    },
    {
      id: 'team',
      name: 'Team',
      selector: (row) => row.team,
      cell: (row) => {
        return (
          <button
            className='project-details-buttons'
            onClick={() => showTeamMembers(row)}
          >
            Team
          </button>
        );
      },
    },
    {
      id: 'report',
      name: 'Report',
      selector: (row) => row.details,
      cell: (row) => {
        return (
          <button
            className='project-details-buttons'
            onClick={() => showPayrollSummary(row)}
          >
            Invoice
          </button>
        );
      },
    },
  ];

  let showPayrollSummary  = async(modalData) => {
    //let 
    setModalData({...modalData});
    setPayrollModal(true);
    
  }

  let showDetails = async(modalData) => {
    let agents = [];
    
    let response = await get("Projects/GetAgentsForProject?projectID="+modalData['id']);
    for (const item of response) {
      agents.push({
        id :item.id,
        agentname :item.firstName + " " +item.lastName,
        username :item.username
      })
      
    }
    let manager = {
      id: modalData.managerId,
      name: modalData.manager 
    }
    setCurrentManager(manager)
    modalData.members = agents;
    setModalData({...modalData});
    setOpenModal(true);
    
  }
  
  let showTeamMembers = async(modalData) => {
    let agents = [];
    
    let response = await get("Projects/GetAgentsForProject?projectID="+modalData['id']);
    for (const item of response) {
      agents.push({
        id :item.id,
        agentname :item.firstName + " " +item.lastName,
        username :item.username
      })
      
    }
    modalData.agents = agents;
    setModalData({...modalData});
    setOpenTeamModal(true);
    
  }

  function closeDetails(row) {
    setOpenModal(false);
    showSuccess("Project was updated")
    getProjects();
    setModalData([]);
  }
  function cancelUpdate(row) {
    setOpenModal(false);
    getProjects();
    setModalData([]);
  }

  function closeDetailsModal() {
    setOpenModal(false);
    setModalData([]);
  }

  function closeTeamMembers(row) {
    setOpenTeamModal(false);
    getProjects();
    setModalData([]);
  }

  function closeTeamMembersModal() {
    setOpenTeamModal(false);
    setModalData([]);
  }

  function closeAddProjectModal() {
    setOpenAddModal(false);
  }

  function openAddProjectsModal() {
    setOpenAddModal(true);
  }

  function closeAddModal() {
    setOpenAddModal(false);
  }
  
  const getProjects = async() => {
    setLoading(true);
    let projectResult
    let projectsList = []
    if(disabled){
      projectResult = await get(`Projects`);
      projectsList = [];
    }else{
      projectResult = await get(`Projects?disabled=${disabled}`);
      projectsList = [];
    }

    for (const item of projectResult) {
      let project = {
        id : item.Id,
        project: item.Name,
        contact: item.ContactName,
        manager: item.Manager !=null ? item.Manager: '',
        managerId: item.ManagerId !=null ? item.ManagerId : 0,
        disabled: item.Disabled,
        shared: item.SharedService,
        hours: item.HourCap,
        maxKeystrokes: item.MaxKeystrokes !=null ? item.MaxKeystrokes : 0,
        maxMouseclicks:item.MaxMouseClicks !=null ? item.MaxMouseClicks : 0,
        maxRepetingKeystrokes:item.MaxRepetingKeystrokes !=null ? item.MaxRepetingKeystrokes : 0,
        maxRepetingMouseclicks: item.MaxRepetingMouseClicks !=null ? item.MaxRepetingMouseClicks : 0,
      }
      
      projectsList.push(project);
      
    }
    setProjects(projectsList);
    setLoading(false);
  }

  function closePayrollModal () {
    setPayrollModal(false);
  }

  const addProject = async(projectObject) => {
    let response = await post("Projects",projectObject);
    if(response == undefined){
      showWarning("Project name and Contact Name can't be empty \n Hour cap must be greater than 0")
    }
    showSuccess("A new project was added")
    closeAddModal();
    getProjects();
  }
  
  useMemo(() => {
      getProjects();
  },[]);

  return (
    <div className='dashboardProjectsMain'>
      <Toast  ref={toast} position='top-center'/>
      <div className='dashboardProjectsMain-header'>Projects</div>
      <div>
        <button
          className='project-add-button'
          id='add-projects-button'
          onClick={openAddProjectsModal}
        >
          Add Project
        </button>
      </div>
      <div className="onlyActive projects-onlyActive">
        <label htmlFor="onlyActive" >Only Active</label>
        {/* <input checked={disabled} type="checkbox" name="onlyActive" onChange={() => toggleOnlyActive()}/> */}
        <Switch onChange={() => toggleOnlyActive()} checked={!disabled} />
      </div>
      {(() => {
              if (!loading){
                  return (
                    <div>

                    <DataTableBase
                      columns={columns}
                      data={projects}
                      tableName='ProjectsReport'
                      fileName={"Projects"}
                    />
                    </div>
                  )
                } else {
                  return <LoadingSpinner/>
                }
              })()}

      {openModal && <ProjectsModal OncurrentManager={currentManager} modalData={modalData} onClose={closeDetailsModal} onCancel={cancelUpdate} updateDetails={showDetails} saveChanges={closeDetails}/>}
      {openTeamModal && <ProjectTeamModal teamMembers={modalData} onClose={closeTeamMembersModal} onCancel={closeTeamMembers} updateTeamMembers={showTeamMembers}/>}
      {payrollModal && <PayrollModal modalData={modalData} onCancel={closePayrollModal}/>}

      {payrollModal && <Backdrop onCancel={closePayrollModal} /> }

      {openModal && <Backdrop onCancel={closeDetailsModal} />}
      {openAddModal && <AddProjectsModal onCancel={closeAddModal} addProject={addProject} onClose={closeAddProjectModal}/>}
    </div>
  );
}

export default DashboardManagerProjects;
