import "./Summary.css";
import DateRangeSelector from "../../ui/DateRangeSelector";
import SummaryCard from "../../ui/SummaryCard";
import SummaryChartCard from "../../elements/summaryElements/SummaryChartCard";
import SearchSection from "../../ui/SearchSection";
import ManagerSubHeaderLinks from "../../layout/header/ManagerSubHeaderLinks";
import DefaultProfileImage from "../../../images/default.jpg"
import { useParams } from "react-router-dom";
import {  post, fetchImageFromAPI, get } from "../../api/apiCalls";
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { baseURL } from "../../api/env";
import UserShorProfile from "../../elements/summaryElements/UserShorProfile";
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";


function UserSummary(props) {
  let [user,setUser] = useState(0);
  let [connect,setConnect] = useState(0);
  const [TotalTime, setTotalTime] = useState(0);
  const [ActiveTime, setActiveTime] = useState(0);
  const [IdleTime, setIdleTime] = useState(0);
  const [ManualTime, setManualTime] = useState(0);
  const [Overtime, setOvertime] = useState(0);
  const [loading, setLoading] = useState(true)
  let [from, setFrom] = useState(0);
  let [to, setTo] = useState(0);
  const [productivityRate, setProductivityRate] = useState(0);
  const params = useParams();
  if(user!=0 ){
    if(user.image == null)
      user.image = "default";

  }
  const getUser = async () => {
    let keys = {
      id : params.employeeId
    };
    let u = await post("Users/GetUserById",keys);
    u.image = u.image === null ? DefaultProfileImage : await fetchImageFromAPI(`${baseURL}TrackingData/GetProfileImages?ImagePath=${u.image}`)
    setUser(u);
    setLoading(false)
  }

  if(connect==0){
    setConnect(1);
    getUser();
  }
  
  const getSummaryDetails = 
  async () => {
   
      let start = moment(from).format("YYYY-MM-DD")
      let end = moment(to).format("YYYY-MM-DD")
    summary = await get(`Reports/GetUserSummary?from=${start}&to=${end}&userID=${params.employeeId}`);
      setTotalTime(summary.totalTime);
      setActiveTime(summary.activeTime);
      setIdleTime(summary.idleTime);
      setManualTime(summary.requested);
      setOvertime(summary.overtime);
      setProductivityRate(parseFloat(summary.activeTime/summary.totalTime).toFixed(1)*100);
  };
  let summary;// = getSummaryDetails();
  const setFromDate = (date) => {
    setFrom(date);
    from = date;
    getSummaryDetails();
  };

  const setToDate = (date) => {
    setTo(date);
    to=date;
    getSummaryDetails();
  };
  useMemo(() => {
    let date = moment();
    setFrom(date);
    setTo(date);
    from = date;
    to = date;
    getSummaryDetails();
  },[]);
 
 
  return (
    <div className="summary">
      {loading && (
        <div className="loading-label">
          <LoadingSpinner />
        </div>
      )}
      {!loading && (
        <>
          <SearchSection />
          <div className="employee-summary-main">
            <div className="my-dashboard-header">
              <UserShorProfile
                employeeName={user.firstName + ' ' + user.lastname}
                employeePronoun={user.pronoun}
                employeeDesignation={user.jobDescription}
                employeeID={user.id}
                employeeUsername={user.username}
                employeeLocation={user.city}
                employeePicture={user.image}
                employeeDisabled={user.disabled}
                employeeContractEndDate={user.contract_endDate}
              />
              <ManagerSubHeaderLinks id={params.employeeId}/>
            </div>
            <div className="employee-summary-box">
              <div className="">
                <div className="employee-summary-info">
                  <div className="employee-summary-cards-box">
                    <div className="selectors">
                      <DateRangeSelector
                        onFromChangeDate = {setFromDate}
                        onToChangeDate = {setToDate}  />
                      {/* <DateSelector placeholderText="From Date" updateTimers={setFromDate} />
                      <DateSelector placeholderText="To Date" updateTimers={setToDate} /> */}
                      <p className="timezone-alert">All time in the summary is on the UTC timezone</p>
                    </div>
                    <div className="employee-summary-cards">
                      <SummaryCard title="Tracked Time" cardtime={TotalTime} info='Your total tracked time via the wisdom tracker.'/>
                      <SummaryCard title="Idle Time" cardtime={IdleTime} info=' Your total Idle time, idle timecards are the ones without activity.'/>
                      <SummaryCard title="Manual Time" cardtime={ManualTime} info='Manual Time is a way to add time to your diary without having to track. This must be approved by your manager.'/>
                      <SummaryCard title="Payroll Time" cardtime={ActiveTime} info='Tracked Time + approved Manual Time.'/>
                      <SummaryCard title="Approved OT" cardtime={Overtime} info='The amount of approved Overtime in the wisdom dashboard. This will allow you to track over the project cap, the time still must be tracked via the tracker.'/>
                    </div>
                  </div>
                  <div className="employee-summary-chard-card">
                    <SummaryChartCard 
                      chartTitle="Productivity Score"
                      Productive={ActiveTime}
                      Idle={IdleTime}
                      Manual={ManualTime}
                      Total={TotalTime}
                      productivityRate={TotalTime}
                    />
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default UserSummary;
