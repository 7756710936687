
import { useMemo, useRef, useState } from 'react';
import { get, post } from '../../api/apiCalls';
import './RejectReasonModal.css';
import { Toast } from 'primereact/toast';
import moment from 'moment';

function RejectReasonModal(props) {
    const toast = useRef(null)
    const [reasons, setReasons] = useState([])
    const [reason, setReason] = useState('default')

    const getReasons = async() => {
        let response = await get(`Request/RejectReason/${props.selectedRow.type}`)
        setReasons(response)
    }

    const handleSelectChange = async(reason) => {
        setReason(reason.target.value)
    }

    const showInfo = (text) => {
      toast.current.show({severity:'info', summary: 'Info', detail:text, life: 3000});
    }

    const reject = async() => {
      if(props.selectedRow.type === "Manual Time"){
        changeManualTimeStatus(props)
      }else{
        changeOvertimeStatus(props)
      }
    }

    const changeManualTimeStatus = async(data) => {
    let formatedValue = data.selectedRow.Hours.toString()
        let keys = {
        date : moment(data.selectedRow.Date).format("YYYY-MM-DD"),
        status : 2,
        managerID : data.managerID,
        userID : data.userID,
        project: data.selectedRow.Project,
        hours: formatedValue,
        reasonID: reason
        };
        let response = await post("Request/ManualTime/ChangeStatusByDate",keys);
        showInfo(response)
        props.onCancel(response)
    }

    const changeOvertimeStatus = async(data) => {
    let keys = {
        date : moment(data.selectedRow.Date).format("YYYY-MM-DD"),
        status : 2,
        managerID : data.managerID,
        userID : data.userID,
        reasonID: reason
    };
    let response = await post("Request/Overtime/ChangeStatusByDate",keys);
    showInfo(response)
    props.onCancel()
    }

    useMemo(() => {
         getReasons();
    }, [])
    return (
        <div className='reject-reason-box'>
          <Toast ref={toast} position="top-center"/>
            <div className="form-modal reject-reason-modal">
                <h4>Reject Reason</h4>
                <select required className='reason-selector' onChange={handleSelectChange}>
                <option value="default"> -- Reason -- </option>
                {reasons.map((reason) => (
                  <option key={reason.id} value={reason.id}>
                    {reason.description}
                  </option>
                ))}
              </select>
              <div className='reject-reason-btns'>
                <button className='reject-reason-save-btn' onClick={reject} disabled={reason === 'default'}>Save</button>
                <button className='reject-reason-cancel-btn' onClick={props.onClose}>Cancel</button>
              </div>
            </div>
        </div>
    )
}

export default RejectReasonModal;
