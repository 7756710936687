import "./AdminSettings.css";
import "./UpdateRate.css";
import { get,post } from "../api/apiCalls";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { useMemo, useRef, useState } from "react";
import Select from 'react-select'
import { AdminUserSettingsHeaderLink } from "../layout/header/AdminUserSettingsHeaderLink";
import LoadingSpinner from "../elements/UiElements/LoadingSpinner";
import moment from "moment";
import DataTableBase from "../ui/DataTableBase";
import { ReactSession } from "react-client-session"
import { Toast } from "primereact/toast";

function UpdateRate () {
    const toast = useRef(null)
    let [data, setData] = useState([]);
    let [selectedUserId, setSelectedUserId] = useState();
    let [loading, setLoading] = useState(false);
    let [usersInSelect, setUsersInSelect] = useState([]);
    let [selectedFrequency, setSelectedFrequency] = useState('');
    const [rateInput, setRateInput] = useState();
    const [currentRate, setCurrentRate] = useState();
    let user;
    const loggedUser = ReactSession.get("User");
    const payFrequencies = [
        {value: 1, label:'weekly'},
        {value: 2, label:'bi-weekly'},
        {value: 3, label:'monthly'}
    ];
    const columns = [
        {
          name: "Update On",
          selector: (row) => row.updatedOn,
          sortable: true,
          reorder: true,
        },
        {
          name: "New Rate",
          selector: (row) => row.toRate,
          sortable: true,
          reorder: true,
        },
        {
          name: "Frequency",
          selector: (row) => row.payFrequency,
          sortable: true,
          reorder: true,
        },
        {
          name: "Update By",
          selector: (row) => row.updatedBy,
          sortable: true,
          reorder: true,
        }
      ];

    const getUsers = async () => {
        let list = []

        let response = await get(`Users/GetAllUsers?disabled=${false}`);
        response.forEach(e => {
            let model = {
                value: '',
                label: ''
            }
            model.value = e.id
            model.label = `${e.firstName} ${e.lastname}`
            list.push(model)
        });
        setUsersInSelect(list)
        
    }
    
    const handleSelectChange = (user) => {
        setSelectedUserId(user.value)
        getUserRate(user.value)
    };

    const handleFrequencySelectChange = (frequency) => {
        setSelectedFrequency(frequency)
    };
    
    const getUserRate = async (id) => {
        setLoading(true)
        setData([])
        let response = await get("Users/GetUserRate?id="+ id).then()
        if(response.id !== undefined){
            let u = {
                id: response.id,
                firstName: response.firstName,
                lastname: response.lastname,
                rate: response.rate,
                payFrequency: response.payFrequency
            }
            setRateInput(u.rate)
            setCurrentRate(u.rate)
            let frequency = payFrequencies.find(x => x.label = u.payFrequency)
            setSelectedFrequency(frequency)

        }
        let data = [];
        data = await get(`Payments/GetUserHistoricalRate?userID=${id}`)
        if(data.result.length > 0)
            assignDataToTable(data.result)
        else{
            setCurrentRate(0)
            setRateInput(0)
            setLoading(false)

        }
    
    }

    let assignDataToTable= (data) => {
    let list = [];
    for (const item of data) {
        list.push( {
        id: item.id,
        userID: item.userID,
        updatedBy: item.updatedBy,
        fromRate: item.fromRate,
        toRate: item.toRate,
        updatedOn : moment(item.updatedOn).format("YYYY-MM-DD HH:mm:ss"),
        payFrequency: item.payFrequency
        });
    }
    setData(list);
    setLoading(false)
    } 

    const showInfo = (text) => {
        toast.current.show({severity:'info', summary: 'Info', detail:text, life: 3000});
    }

    const updateUserRate = async() => {
        let keys = {
            userID: selectedUserId,
            FromRate: parseFloat(currentRate), 
            ToRate: parseFloat(rateInput),
            UpdatedBy: `${loggedUser.firstName} ${loggedUser.lastname}`,
            PayFrequency: selectedFrequency.label

        }
        let reponse = await post('Payments/UpdateUserRate', keys)
        showInfo(reponse)
        getUserRate(selectedUserId)
    }

    useMemo(() => {
        getUsers()
      },[]);

    return (
   
    <div className="settingsContainer">
        <AdminUserSettingsHeaderLink/>
        <Toast ref={toast} position="top-center"/>
        {  
            <Box sx={{ flexGrow: 1 }} className="settingsBody">
                <Grid container spacing={2}>
                    <Grid xs={10}>
                        <h3 className="settingsLabel">Update Rate</h3>

                    </Grid>
                    <Grid xs={10} className="update-fields">
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="color"
                            value={user}
                            defaultValue={user}
                            onChange={handleSelectChange}
                            options={usersInSelect}
                        />
                        {selectedUserId && (
                            <div className="user-rate">
                                <div>
                                    <input
                                    className="rate-input"
                                    type="number"
                                    value={parseFloat(rateInput)}
                                    onChange={e => setRateInput(e.target.value)}
                                    />
                                </div>
                                <Select
                                    className="frequency-drop"
                                    classNamePrefix="select"
                                    name="color"
                                    value={selectedFrequency}
                                    defaultValue={selectedFrequency}
                                    onChange={handleFrequencySelectChange}
                                    options={payFrequencies}
                                />
                                <button onClick={updateUserRate} className="save-rate-btn">Save</button>   
                            </div>
                        )}
                    </Grid>
                    <div className="rate-table">
                        {loading === true
                            ? <LoadingSpinner />
                            : <DataTableBase columns={columns} data={data}  fileName={"User_Historical_Rate"} showDownloadButton={false}/>
                        }
                    </div>
                </Grid>
            </Box>
        }
    </div>);
}

export default UpdateRate;