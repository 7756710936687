import { useState  } from "react";
import { get } from "../../api/apiCalls";
import { ReactSession } from 'react-client-session';
import Alert from 'react-bootstrap/Alert';
import Joyride from "react-joyride"
import { useNavigate } from "react-router-dom";
import ProfileSubHeaderLinks from "../../layout/header/ProfileSubHeaderLinks";
import ManagerSidebar from "../../layout/sidebar/ManagerSidebar";


function Payment(props) {
  
  const [paymentUrl, setPaymentUrl] = useState('')
  const history = useNavigate();
  let user = ReactSession.get("User")
  const donwloadFilePath = `${process.env.PUBLIC_URL}/Wisdom-Payments-Guide.pdf`
  const [{ run, steps }, setState] = useState({
    run:true,
    steps: [
      {
        content: "Now, to the most important part of your onboarding. Here you will find anything related to payments made to you, including a history!",
        title: <h2>Payments page</h2>,
        locale: {skip: <strong>SKIP</strong>},
        placement: "center",
        target: 'body'
      },
      {
        content: 
        <p>
          Please take a look at the document in the link below to get more information about this configuration.
        </p>,
        placement: "top",
        target: "#step-paymentDoc",
        title: <h2>Payments Docs</h2>,
        spotlightClicks: true
      },
      {
        content: 
        <p>Make sure to complete this otherwise we cannot guarantee you will be paid on time</p>,
        placement: "top",
        target: "#step-payment",
        title: <h2>Payments</h2>,
        spotlightClicks: true
      },
    ]
  })
  
  const handleTourEvent = (event) => {
    if(event.type == "tour:end"){
      history("/profile/requests")
    }
  }

  get("Payments/GetPaymentsUrl?userEmail="+user.username+"&userPersonalEmail="+user.personalEmail).then((response) => {
    setPaymentUrl(response)
  }, (error) => console.log("ERROR: ", error))


  
  return (
    <div className="profileMain">
      <ProfileSubHeaderLinks />
      <div className="manual-hours">
        {user.isFirstLogin && (
          <Joyride
            continuous
            showSkipButton
            run={run}
            steps={steps}
            showProgress
            disableScrolling
            callback={handleTourEvent}
            styles={{
              options: {
                zIndex: 9999,
              }
            }}
          />
        )}
            <Alert variant="success">
              <Alert.Heading>Payment Instructions</Alert.Heading>
              <p>
                Please find in <a href={donwloadFilePath} download="Wisdom-Payments-Guide.pdf" id="step-paymentDoc">this link</a> instructions to setup your payment details
              </p>
            </Alert>
            {paymentUrl && (
                <iframe src={paymentUrl} width="100%" height='450' frameBorder="0" id="step-payment"></iframe>
            )}
      </div>
    </div>
  );
}

export default Payment;
