import SearchSection from "../../ui/SearchSection";
import './UserDailyDiary.css'
import ManagerSubHeaderLinks from "../../layout/header/ManagerSubHeaderLinks";
import { useParams } from "react-router-dom";
import React, { useState, useEffect, useRef} from 'react';
import moment from 'moment';
import { fetchImageFromAPI, get, post, put } from "../../api/apiCalls";
import "./Diary.css";
import DateSelector from "../../ui/DateSelector";
import TimezoneSelector from "../../ui/TimezoneSelector";
import DiaryTimeLine from "../../elements/diaryElements/DiaryTimeline";
import { Box, Modal, Typography } from "@mui/material";
import { ReactSession } from 'react-client-session'
import Select from 'react-select'
import LoadingSpinner from "../../elements/UiElements/LoadingSpinner";
import { baseURL } from "../../api/env";
import UserShorProfile from "../../elements/summaryElements/UserShorProfile";
import { Toast } from "primereact/toast";
import DefaultProfileImage from '../../../images/default.jpg'
import { Dialog } from "primereact/dialog";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

function UserDailyDiary(props) {
  const toast = useRef(null)
  const params = useParams();
  let [date, setDate] = useState(0);
  let [timeOffset,setTimeOffset] = useState(0);
  let [timecards, setTimecards] = useState([]);
  let [summary, setSummary] = useState([]);
  let [loading,setLoading] = useState(true);
  let [selectedDiaryDate,setSelectedDiaryDate] = useState(moment().format("YYYY-MM-DD"));
  let [projects, setProjects] = useState([])
  let [project, setProject] = useState()
  let [euser,setEuser] = useState(0);
  let [connect,setConnect] = useState(0);
  let[loadingImages, setLoadingImages]= useState(true);
  const [disableUploadButton, setDisableUploadButton] = useState(true)
  const [visible, setVisible] = useState(false)
  const [loggedUser, setLoggedUser] = useState()
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const setDiaryDate = (date) => {
    setDate(date);
    setSelectedDiaryDate(date);
    selectedDiaryDate = date;
    getSummaryDetails();
  };

  const showInfo = (text) => {
    toast.current.show({severity:'success', summary: 'Success', detail:text, life: 3000});
  }

  const getSummaryDetails = async (offset) => {
		const keys = {
		  date: moment(selectedDiaryDate).format("YYYY-MM-DD"),
		  id: params.employeeId,
      offset
		};
	  
		let summary = await fetchSummaryData(keys);
		if (summary.length <= 0) {
      setSummary([])
		} else {
      setSummary(summary);
		}
    setLoadingImages(false)
    setLoading(false)
    setDisableUploadButton(false)
	};
	const fetchSummaryData = async (keys) => {    
		try {
		  const response = await get("TrackingData/DiaryUserTrackingData", keys);
		  return response;
		} catch (error) {
		  console.error("Error fetching summary data:", error);
		  return undefined;
		}
	};
  
  const handleUpdateCards = async() => {
    setOpen(true)
    let checked = document.querySelectorAll('input:checked');
    let num = [];
    for (const item of checked) {
      num.push(parseInt(item.id));     
      item.checked = true;
    }
    setTimecards(num)
  }

  let changeTimeOffset = (Offset) => {
    timeOffset = Offset
    getSummaryDetails(timeOffset);
  }
  
  const getUserProjects = async() => {
    let key = {
      UserId: params.employeeId
    }
    let list = []
    let response = await get("Projects/GetProjectsForAgent?UserId="+key.UserId)
    response.forEach(e => {
      let model = {
        value:'',
        label:''
      }
      model.value = e.id
      model.label = e.name
      list.push(model)
    });
    setProjects(list)
  }
      
  const handleSelectChange = (project) => {
    setProject(project)
  }

  const uncheckTimeCards = () => {
    let checked = document.querySelectorAll('input:checked');
    for (const item of checked) {
      item.checked = false;
    }
    setTimecards([])
  }

  const handleSubmitUpdateTimeCard = async() => {
    setOpen(false)
    setProject()
    uncheckTimeCards()
    let keys = {
      cardsId: timecards,
      projectId: project.value,
      projectName: project.label
    }
    let response = await put("TrackingDataAudit/UpdateTimeCards", keys)
    showInfo(response)
    getSummaryDetails();
  }   

  const getUser = async () => {
    let keys = {
      id : params.employeeId
    };
    let u = await post("Users/GetUserById",keys);
    u.image = u.image === null ? DefaultProfileImage : await fetchImageFromAPI(`${baseURL}TrackingData/GetProfileImages?ImagePath=${u.image}`)
 
    setEuser(u);
    setLoading(false)
  }

   if(connect==0){
    setConnect(1);
    getUser();
  }

  const handleSelection = () => {
    console.log("Card selecionado")
  }

  useEffect(() => {
    setDisableUploadButton(true)
    let user = ReactSession.get("User")
    setLoggedUser(user)
    const fetchData = async() => {
      try {

        await getUserProjects()
        await getSummaryDetails();
        await getUser()
      } catch (error) {
        console.error("ERROR: ", error)
      }
    }
    
    fetchData()
  },[]);

  return (
    <div className="summary">
      <Toast ref={toast} position="top-center"/>
      <SearchSection />
      {loading && <LoadingSpinner />}
      {!loading && (
        <div className="summaryMain">
          <div className="my-dashboard-header">
            <UserShorProfile
              employeeName={euser.firstName + ' ' + euser.lastname}
              employeePronoun={euser.pronoun}
              employeeDesignation={euser.jobDescription}
              employeeID={euser.id}
              employeeUsername={euser.username}
              employeeLocation={euser.city}
              employeePicture={euser.image}
              employeeDisabled={euser.disabled}
              employeeContractEndDate={euser.contract_endDate}
            />
            <ManagerSubHeaderLinks id={params.employeeId}/> 
          </div>
          <div className="diaryMain">
            <div className="filters">
              <span>Diary</span>
              <DateSelector placeholderText={moment().format("YYYY-MM-DD")}  updateTimers={setDiaryDate} />
              <span>Timezone</span>
              <TimezoneSelector setTimeOffset={changeTimeOffset} value="Etc/GMT" />
              {loading && <LoadingSpinner />}
            </div>
            <button
              className="timecards-action-btns"
              id="update-cards"
              onClick={handleUpdateCards}
              disabled={disableUploadButton}
            >
              Update Timecard
            </button>
            <button
              style={{marginLeft:'8px'}}
              className="timecards-action-btns"
              id="timecard-status"
              onClick={() => setVisible(!visible)}
            >
              Timecard Status
            </button>
            {summary.length <= 0 && !loading && (
                <p className="no-data-msg">No timecards for the selected day</p>
            )}
            <DiaryTimeLine
              data={summary}
              handleSelection={handleSelection}
            />
          </div>
        </div>

      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update time card
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Select the project the timecard should be moved to.
          </Typography>
          <Box className="update-timecards-box">
            <Select
                className="basic-single timecards-selection"
                classNamePrefix="select"
                placeholder="Select a project"
                name="color"
                value={project}
                defaultValue={project}
                onChange={handleSelectChange}
                options={projects}
            />
            <button
              className="confirm-timecards-update-btn"
              onClick={handleSubmitUpdateTimeCard}
              disabled={project === null || project === undefined || timecards.length <= 0}
            >
              Update
            </button>
            {timecards.length <= 0 &&(
              <span className="update-timecards-info-text">* You need to select at least one time card before update</span>
            )}
          </Box>
        </Box>
      </Modal>
      <Dialog 
      header="Timecards Status" 
      visible={visible} 
      style={{ width: '50vw' }} 
      onHide={() => {if (!visible) return; setVisible(false); }}
      >
        <div>
          <table className="timecard-dialog">
            <thead className="timecard-dialog">
                <tr className="timecard-dialog">
                    <th className="timecard-dialog">Status</th>
                    <th className="timecard-dialog">Description</th>
                    <th className="timecard-dialog">Added to Payroll</th>
                </tr>
            </thead>
            <tbody className="timecard-dialog">
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Idle</td>
                <td className="timecard-dialog">Idle timecard is when the tracker did not identify any activity.</td>
                <td className="timecard-dialog">No</td>
              </tr>
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Productive</td>
                <td className="timecard-dialog">A productive and valid timecard</td>
                <td className="timecard-dialog">Yes</td>
              </tr>
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Manual Time</td>
                <td className="timecard-dialog">A timecard created after MT was approved</td>
                <td className="timecard-dialog">Yes</td>
              </tr>
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Marked</td>
                <td className="timecard-dialog">An idle timecard marked as not idle by the user. Example: the user was in a meeting or watching a training.</td>
                <td className="timecard-dialog">Yes</td>
              </tr>
              <tr className="timecard-dialog">
                <td className="timecard-dialog">Rejected</td>
                <td className="timecard-dialog">A timecard that has been rejected by the manager </td>
                <td className="timecard-dialog">No</td>
              </tr>
            </tbody>
          </table>
        </div>
        <button className="ok-dialog-btn" onClick={() => setVisible(!visible)}>Ok</button>
          
      </Dialog>      
    </div>
  );
}

export default UserDailyDiary;
